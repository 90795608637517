<template>
  <div>
    <div class="searchbox">
      <el-form inline size="mini">
        <el-form-item label="酒店名称" class="marginright20">
          <el-input
            @input="searchFn"
            size="mini"
            v-model="searchData.key"
            class="width140"
          ></el-input>
        </el-form-item>
        <el-form-item label="日期选择" class="marginright20">
          <el-date-picker
            size="mini"
            class="width230"
            v-model="chooseDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="changeSearchDate"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="促销类型" class="marginright20">
          <el-select
            size="mini"
            @change="searchFn"
            v-model="searchData.pname"
            class="width100"
          >
            <el-option
              v-for="(item, index) in pnameList"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="促销状态" class="marginright20">
          <el-select
            size="mini"
            @change="searchFn"
            v-model="searchData.state"
            class="width100"
          >
            <el-option
              v-for="item in optionsList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="searchFn"
            >查询</el-button
          >
          <el-button type="primary" plain size="mini" @click="clearFn"
            >清空</el-button
          >
          <el-button type="success" size="mini" @click="toAdd"
            >新建促销</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="mainbox">
      <div class="miantitleflexbox">
        <div>
          <el-button type="primary" size="mini" @click="openchangeStateDia"
            >批量开启/停用</el-button
          >
        </div>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchData.page"
            :page-size="searchData.rows"
            :page-sizes="[15, 30, 45, 60]"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <el-table
          :data="tableList"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="促销类型" prop="pname"> </el-table-column>
          <el-table-column label="酒店名称" prop="hotelName"> </el-table-column>
          <el-table-column label="售卖房型" prop="roomNum"> </el-table-column>
          <el-table-column label="促销日期" prop="ptime"> </el-table-column>
          <el-table-column label="促销折扣" prop="pm"> </el-table-column>
          <el-table-column label="促销状态" prop="state">
            <template slot-scope="scope">
              <el-tag size="mini" type="success" v-if="scope.row.state == 1"
                >启用</el-tag
              >
              <el-tag size="mini" type="danger" v-else-if="scope.row.state == 0"
                >停用</el-tag
              >
              <el-tag size="mini" type="info" v-else>未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="toEditPage(scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                size="mini"
                v-if="scope.row.state == 0"
                @click="changeCtripPromoteState(scope.row)"
                >启用</el-button
              >
              <el-button
                v-if="scope.row.state == 1"
                type="text"
                class="redcolor"
                size="mini"
                @click="changeCtripPromoteState(scope.row)"
                >停用</el-button
              >
              <el-button
               
                type="text"
                class="redcolor"
                size="mini"
                @click="delCtripPromote(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="促销批量开启/停用"
      width="400px"
      :visible.sync="stateDiaVisible"
    >
      <div >
        <div style="text-align:center;">
           批量操作 <el-radio v-model="stateRadio" label="1" size="mini" border>开启</el-radio>
        <el-radio v-model="stateRadio" label="0" size="mini" border>停用</el-radio>

        </div>
       
        <div class="diafooterbtn">
        <el-button
          @click="closeFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handStateData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCtripPromoteList_api,
  changeCtripPromoteState_api,
  delCtripPromote_api
} from "../../apis/hotelPackage";
export default {
  data() {
    return {
      searchData: {
        rows: 15,
        page: 1,
        key: "",
        start: "",
        end: "",
        pname: "",
        state: "",
      },
      chooseDate: [],
      optionsList: [
        { label: "全部", value: "" },
        { label: "停用", value: "0" },
        { label: "启用", value: "1" },
      ],
      pnameList: [
        { label: "全部", value: "" },
        { label: "午夜特惠", value: "午夜特惠" },
        { label: "今夜甩卖", value: "今夜甩卖" },
        { label: "出行特惠", value: "出行特惠" },
        { label: "门店新客", value: "门店新客" },
        { label: "天天特价", value: "天天特价" },
        { label: "连住特惠", value: "连住特惠" },
        { label: "提前预定", value: "提前预定" },
        { label: "限时抢购", value: "限时抢购" },
      ],
      total: 0,
      tableList: [],
      checkedList: [],
      checkedIds: "",
      stateDiaVisible: false,
      stateRadio:'1'
    };
  },
  created() {
    this.getCtripPromoteList();
  },
  methods: {
    searchFn() {
      this.searchData.page = 1;
      this.getCtripPromoteList();
    },
    clearFn() {
      this.searchData.key = "";
      this.searchData.start = "";
      this.searchData.end = "";
      this.searchData.pname = "";
      this.searchData.state = "";
    },
    //分页-每页条数
    handleSizeChange(val) {
      this.searchData.rows = val;
      this.searchFn();
    },
    //分页-跳转页数
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.getCtripPromoteList();
    },
    //列表选择
    handleSelectionChange(val) {
      this.checkedList = val;
    },
    toEditPage(row) {
       this.$router.push({
        //path:'/addTravelPromote'
         path:'/addMidnightPromote',
         query:{
         pid:row.id
        }

      })
    },
    toAdd() {
      this.$router.push({
        path: "/addPromotion",
      });
    },
    //获取携程促销列表
    async getCtripPromoteList() {
      let { code, data } = await getCtripPromoteList_api(this.searchData);
      if (code == 0) {
        this.total = data.total;
        this.tableList = data.records;
      }
    },
    //切换携程促销状态
    changeCtripPromoteState(row) {
      let stateLabel;
      if (row.state == 0) {
        stateLabel = "启用";
      } else if (row.state == 1) {
        stateLabel = "停用";
      }
      this.$confirm(`确定${stateLabel}此促销？`, "提示", {
        type: "warning",
      })
        .then(() => {
          this.changeCtripPromoteStateFn(row);
        })
        .catch(() => {});
    },
    async changeCtripPromoteStateFn(row) {
      let upState;
      if (row.state == 0) {
        upState = "1";
      } else if (row.state == 1) {
        upState = "0";
      }
      let { code, data } = await changeCtripPromoteState_api({
        pids: row.id,
        state: upState,
      });
      if (code == 0) {
        this.$message.success("操作成功");
        this.getCtripPromoteList();
      }
    },
    changeSearchDate() {
      console.log(this.chooseDate);
      this.searchData.start = this.chooseDate[0];
      this.searchData.end = this.chooseDate[1];
      this.searchFn();
    },
    openchangeStateDia() {
      if (this.checkedList.length==0) {
        this.$message.error("请勾选至少一个促销");
      } else {
        // this.checkedIds = this.checkedList.join(",");
        let idsArr=[]
        this.checkedList.forEach(item=>{
          idsArr.push(item.id)
        })
        this.checkedIds = idsArr.join(",");
        this.stateDiaVisible = true;
      }
    },
  async  handStateData(){
     let { code, data } = await changeCtripPromoteState_api({
        pids: this.checkedIds,
        state: this.stateRadio,
      });
      if (code == 0) {
        this.$message.success("操作成功");
         this.stateDiaVisible = false;
        this.getCtripPromoteList();
      }
       
    },
    closeFn(){
      this.stateDiaVisible = false;
    },
    delCtripPromote(row){
      this.$confirm(`确定删除此促销？`, "提示", {
        type: "warning",
      })
        .then(() => {
          this.delCtripPromoteFn(row);
        })
        .catch(() => {});
    },
   async delCtripPromoteFn(row){
     let {code,data}=await delCtripPromote_api({
      pids:row.id
     })
     if(code==0){
      this.$message.success("删除成功");
        this.getCtripPromoteList();
     }
    }
  },
};
</script>

<style lang="less" scoped>
.searchbox {
  width: 1160px;
  padding: 20px 20px 2px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
.width100 {
  width: 100px;
}
.width140 {
  width: 140px;
}
.width230 {
  width: 230px;
}
.marginright20 {
  margin-right: 20px;
}
.mainbox {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
.miantitleflexbox {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.redcolor {
  color: #ee4d52;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

